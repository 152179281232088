.earn-content-container.theme--light {
  .tab-content-title {
  }

  .desc {
  }

  .tab-content-desc {
  }

  .tab-main-content {
    .main-content {
      > .heading {
      }
    }

    .overview-container {
      & + .overview-container {
      }

      .main-title {
        &.custom {
          & > span {
            &.highlight {
            }
          }
        }
      }

      .rewards-wrap {
      }

      .total-reward {
        .reward-img {
        }

        .reward-info {
          .reward-title {
          }

          .reward-value {
          }
        }
      }

      .overview-content-container {
        .overview-content {
          &:first-child {
          }

          .title {
          }

          .value {
            span {
            }
          }
        }
      }

      .actions-contaner {
        &.no-account {
        }

        button {
        }
      }
    }

    .right-content {
      .left-box-content {
      }
      .right-content-box {
        &:first-child {
        }
        .box-info {
          .title {
            &.custom {
              > span {
              }
            }
          }

          .value {
            .Tooltip {
              .Tooltip-handle {
              }
            }

            .sub-value {
            }
          }
        }
      }

      .right-content-border-box {
        .box-main-price {
          background: radial-gradient(285.88% 111.8% at 100% 0%, #f2fffb 0%, #daf2eb 100%);

          .price-info {
            .title {
              color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
            }

            .value {
            }
          }

          img {
          }
        }

        .buy-btn-container .default-btn {
          span {
            font-weight: 700;
          }
        }

        .box-devider {
          .divider-title {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
          }

          .divider-line {
            background: var(--Border, rgba(13, 26, 22, 0.07));
          }
        }

        .box-apr {
          .Tooltip-handle {
            color: var(--Primary, #02b27f);
          }
        }

        .box-token-info {
          .title {
            color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
          }

          .value {
            color: var(--Text-Text_Primary, #0d1a16);

            .Tooltip-handle {
              color: var(--Text-Text_Primary, #0d1a16) !important;

              & > span {
                color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
              }
            }

            & > span {
              color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
            }
          }
        }
      }
    }

    .left-content {
      .left-box-content {
        &:first-child {
        }

        .main-title-container {
          .pending-rewards-value {
            color: var(--Primary, #02b27f);
          }
        }

        .main-title {
        }

        .text-button-green {
          a {
          }
        }
        .pending-rewards-content {
        }
        .rewards-container {
          .reward-info {
            .title {
            }

            .value {
              span {
              }
            }
          }
        }

        .box-desc {
        }

        .rewards-es-container {
          .account-info {
            .reward-info {
              &:first-child {
              }

              .title {
              }

              .value {
                span {
                  color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7));
                }
              }
            }
          }

          .button-actions {
            button:not(.border-btn) {
              background: var(--Primary, #02b27f);
              span {
                color: #fff;
              }
            }
          }
        }
        .glp-claim-reward {
        }
      }
    }
  }

  @media screen and (max-width: 1033px) {
    .tab-main-content {
      &.overview {
      }

      .main-content {
      }
    }

    // padding-bottom: 120px;
  }

  .native-token-reward {
    .span-html {
    }
  }
}
