.PositionDropdown-dots-icon {
  font-size: 1rem;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  display: inline-flex;

  margin-top: 0.3rem;
  background: rgba(255, 255, 255, 0);
  border-radius: 2rem;
  padding: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 900px) {
  .PositionDropdown-dots-icon {
    /* width: 24px; */
    height: 24px;
  }
}
.PositionDropdown-dots-icon:hover {
  color: rgba(255, 255, 255, 1);
  background: rgba(255, 255, 255, 0.1);
}

.PositionDropdown-extra-options {
  position: relative;
}

.PositionDropdown-extra-options .menu-items {
  top: 1rem;
  min-width: 15.5rem;
}
