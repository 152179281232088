.theme--light {
  .analytic-v1-wrapper {
    .title-head {
      color: #fff !important;
    }
    .tab-container,
    .react-dropdown-select-dropdown,
    .rdrCalendarWrapper {
      background-color: #fff !important;
    }
    .tab--actived {
      background: rgba(2, 178, 127, 0.15) !important ;
      > div {
        color: #02b27f !important;
      }
    }
    .tab-container {
      a {
        &:hover {
          > div {
            color: #02b27f !important;
          }
        }
      }

      // &::-webkit-scrollbar-track {
      //   background: unset !important;
      //   background-color: rgba(0, 0, 0, 0.1) !important;
      // }

      // &::-webkit-scrollbar {
      //   background: rgba(0, 0, 0, 0.1) !important;
      // }

      // &::-webkit-scrollbar-thumb {
      //   background-color: rgba(0, 0, 0, 0.6) !important;
      // }
    }
    .label-last-update {
      color: rgba(13, 26, 22, 0.7) !important;
    }
    .page-description,
    .total-stat-label {
      color: rgba(13, 26, 22, 0.7) !important;
      a {
        color: rgba(13, 26, 22, 0.7) !important;
        &:hover {
          color: rgba(0, 0, 0, 1) !important;
        }
      }
    }
    .desc {
      color: rgba(255, 255, 255, 0.6) !important;
      a {
        color: rgba(255, 255, 255, 0.6) !important;
        &:hover {
          color: rgba(255, 255, 255, 1) !important;
        }
      }
    }
    .react-dropdown-select,
    .chart-cell,
    .chart-control-checkbox,
    .price-content-container {
      border: 1px solid rgba(13, 26, 22, 0.07) !important;
      background-color: #fff !important;

      .price-content {
        &.header {
          border-bottom: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
        }

        // & > span {
        //   color: #02b27f;
        // }

        &::before {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }

      .token-info {
        .timestamp {
          color: var(--Text-Text_Secondary, rgba(13, 26, 22, 0.7)) !important;
        }
      }
    }
    .chart-control-checkbox {
      &:hover {
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
    .chart-cell.stats {
      background-color: transparent !important;
      border: none !important;
    }

    .stat-overview-wrapper {
      border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
      background: linear-gradient(116deg, rgba(50, 205, 239, 0.2) 16.49%, rgba(165, 219, 250, 0) 83.51%) !important;
    }
    .total-stat-delta {
      color: #02b27f !important;
    }

    .rdrNextPrevButton {
      border: 1px solid #1d2429 !important;
    }
    .rdrPprevButton {
      i {
        border-color: transparent #1d2429 transparent transparent !important;
      }
    }
    .rdrNextButton {
      i {
        border-color: transparent transparent transparent #1d2429 !important;
      }
    }
    .rdrWeekDays {
      span {
        color: rgb(132, 144, 149) !important;
      }
    }
    .rdrDayNumber {
      span {
        color: #1d2429 !important;
      }
    }
    .rdrMonthAndYearPickers {
      select {
        color: #000 !important;
        background: #fff !important;
        border: 1px solid rgb(52, 73, 94) !important;
      }
    }
    .body-table a {
      background: #fff !important;
      border: 1px solid rgba(13, 26, 22, 0.07);
    }
    .time-wrapper {
      div {
        color: rgba(13, 26, 22, 0.7);
        &.active {
          color: #02b27f !important;
        }
      }
    }

    .custom-tooltip {
      // background: linear-gradient(180deg, #f7fffd 0%, #eef1f7 100%) !important;
    }

    .no-data-record {
      border: 1px solid rgba(13, 26, 22, 0.07);
    }

    .border-theme {
      tr:not(:first-child) {
        box-shadow: 0 0 0 1px #0d1a1612;
        border-radius: 12px;
      }

      a:has(img[alt="share"]) {
        &:hover {
          img {
            filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
          }
        }
        img {
          filter: brightness(0);
        }
      }
    }

    .account-postion-details {
      color: #1d2429 !important;
    }
    .link-share {
      img {
        filter: brightness(0);
      }
    }
    .position-detail-head {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.6);
      .name {
        color: #0d1a16;
      }
      .wallet-top {
        color: #0d1a16;
      }
      .price {
        color: rgba(13, 26, 22, 0.7);
        .value {
          color: #0d1a16;
        }
      }
      a {
        color: #0d1a16;
      }
    }
    .position-detail-content {
      .title {
        color: rgba(13, 26, 22, 0.7);
        .value {
          color: #0d1a16;
        }
        span {
          color: #0d1a16;
        }
      }
    }
    .row-item-body {
      box-shadow: rgba(13, 26, 22, 0.07) 0px 0px 0px 1px;
      border-radius: 12px;
      td {
        background: #fff;
      }
    }
    .table-head {
      th {
        color: rgba(13, 26, 22, 0.7) !important;
      }
    }

    .account-container {
      .info {
        span {
          color: #000;
        }

        a:has(img[alt="share"]) {
          &:hover {
            img {
              filter: invert(82%) sepia(64%) saturate(513%) hue-rotate(87deg) brightness(98%) contrast(97%);
            }
          }
          img {
            filter: brightness(0);
          }
        }
      }

      .group-info {
        .child {
          background: #fff;
          border: 1px solid rgba(13, 26, 22, 0.07);
          .label {
            color: #0d1a16b2 !important;
          }
        }
      }

      .btn.btn-primary.btn-left.btn-lg:not(.search-btn) {
        border: 1px solid rgba(13, 26, 22, 0.07);

        &:hover {
          background: rgba($color: #02b27f, $alpha: 0.15);
        }
      }

      .sub-tabs {
        font-size: 12px !important;
        .child {
          color: rgba(0, 0, 0, 0.6);

          &:hover {
            color: #02b27f !important;
          }
        }
      }
      .table,
      .table-mobile {
        .header {
          .child {
            color: #0d1a16b2 !important;
            font-size: 12px;
            .Tooltip-handle {
              color: #0d1a16b2 !important;
            }
          }
        }
        border: 1px solid rgba(13, 26, 22, 0.07);
        background: #fff;
        .content {
          .time {
            color: #0d1a16b2 !important;
          }
        }
      }
      .coin {
        color: #0d1a16 !important;
      }
    }
    .view-details a {
      color: #02b27f !important;
      &:hover {
        color: #02845e !important;
      }
    }
    .position-history-mobile-wrapper {
      background: #fff !important;
      .body-position-mobile-wrapper {
        div {
          &::before {
            color: rgba(13, 26, 22, 0.7) !important;
          }
        }
      }
    }
    .position-trader-wrapper {
      div {
        &::before {
          color: rgba(13, 26, 22, 0.7) !important;
        }
      }
    }
    .title.liquidation .value {
      color: #d49007 !important;
    }
    .header-table {
      font-size: 12px !important;
      span {
        color: #0d1a16b2 !important;
      }
    }
    .btn-label {
      color: #02b27f !important;
      &:hover {
        color: #03805b;
      }
    }
    .reload {
      path {
        color: rgba(13, 26, 22, 0.7) !important;
      }
      &:hover {
        path {
          color: #02b27f !important;
        }
      }
    }
    .copy-code {
      color: #02b27f !important;
    }
    .code-item-group {
      background-color: #fff !important;
      border: 1px solid rgba(13, 26, 22, 0.07) !important;
    }
  }
  .analytic-v1-wrapper {
    .minus {
      color: #d62f44 !important;
    }
    .account-container {
      .table {
        .content,
        .theme--light {
          border-bottom: 1px dashed rgba(13, 26, 22, 0.07);
        }
      }

      .btn-left {
        border: 1px solid rgba(13, 26, 22, 0.07);
        span {
          color: #0d1a16 !important;
        }

        &:hover {
          border: 1px solid #02b27f !important;
          background: transparent !important;
          span {
            color: #02b27f !important;
          }
        }
      }
      .search-btn {
        border: none;

        &:hover {
          border: none !important;

          span {
            color: #02b27f !important;
            opacity: 1 !important;
          }
        }
      }
      .no-data-record {
        margin-top: 12px !important;
        border: 1px solid rgba(13, 26, 22, 0.07) !important;
      }
    }
  }
}
