.Exchange-updated-ui {
  margin: 0;
  width: 100%;
  max-width: 100%;
  padding-top: 15px !important;
  padding-bottom: 0 !important;

  .Exchange-content {
    padding-left: 0;
    padding-right: 0;
    grid-gap: 0;
    padding-bottom: 0;

    // height: calc(100vh - 62px) !important;
    .ExchangeChart-top {
      border-radius: 0;

      .ExchangeChart-info-label {
        font-size: 14px;

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          /* 19.6px */
        }
      }
    }

    .ExchangeChart-additional-info {
      span {
        line-height: 140%;
        /* 19.6px */
      }
    }

    .ExchangeChart-top {
      border-bottom: 1px solid var(--Border, rgba(255, 255, 255, 0.1));
      background: none;
    }

    .swap-box-v2 {
      border-radius: 0;
      margin-bottom: 0;
      background: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .ExchangeChart-bottom {
      top: 76px;
      border-radius: 0;
    }

    .App-box-highlight {
      border-radius: 0;
    }

    .Exchange-swap-box {
      // min-height: calc(100vh - 62px) !important;
      height: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 700px) {
        min-height: unset !important;
      }
    }

    .info-main-price {
      .deltaChange {
        display: none;
      }
    }

    @media screen and (max-width: 1350px) {
      padding-bottom: 46px;

      .ExchangeChart-bottom {
        display: none;
      }

      .ExchangeChart {
        height: fit-content !important;
      }

      .Exchange-lists {
        padding-left: 16px;
        padding-right: 16px;
      }

      .Exchange-should-show-position-lines {
        display: none;
      }

      .ExchangeChart-top-inner {
        .ExchangeChart-title {
          margin-bottom: 0;
        }

        .changed-24h {
          display: none;
        }

        .info-main-price {
          position: absolute;
          right: 16px;
          top: 22px;
          text-align: right !important;

          .deltaChange {
            display: inline-block;
          }

          .ExchangeChart-info-label {
            display: none;
          }
        }

        .ExchangeChart-additional-info {
          width: 45%;
          margin-top: 16px;

          // @media screen and (max-width: 700px) {
          //   width: auto;
          // }
        }
      }
    }

    @media screen and (max-width: 700px) {
      .ExchangeChart-top-inner {
        gap: 0 !important;
      }

      .Exchange-lists.small {
        background: transparent !important;
        margin-top: 0;
      }

      .Exchange-list.small {
        margin-top: 16px;
      }

      .Exchange-left {
        .ExchangeChart-top {
          padding-top: 0 !important;
        }
      }

      .info-main-price {
        top: 5px !important;
        text-align: right;
      }

      .Exchange-right {
        padding-left: 16px;
        padding-right: 16px;

        .Exchange-swap-box {
          border-radius: 16px !important;
        }
      }

      .ExchangeChart-top {
        border-bottom: none !important;
      }

      .exchange-section-first {
        margin-bottom: 16px !important;
      }

      .Exchange-swap-ball {
        top: -28px;
      }
    }
  }

  .Exchange-lists {
    background: none;

    .Exchange-list-tabs {
      background-color: #212224;
      border-radius: 12px;
      display: flex;
      height: 44px;

      .Tab-option {
        border-radius: 12px;
        font-style: normal;
        margin-right: 0px;
        flex-direction: column;
        font-style: normal;

        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        color: var(--Text-Text_Secondary, #919499);
        font-size: 12px;
        font-weight: 700;
        line-height: 140%;
        /* 16.8px */
        border: 2px solid transparent;
      }

      .active {
        color: white !important;
        border-radius: var(--Border-Radius-Medium, 12px);
        border: 2px solid #41454d;
        background: var(--Nature-3, #2e3033);
      }
    }

    .main-wrapper {
      width: 100%;
      max-width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .table-line-info {
        background: #18191a;
      }
    }
  }
}

.pad-chart-container {
  display: none;
  z-index: 100;

  @media screen and (max-width: 1110px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #090a11;
    /* White/10 */
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.25);

    // height: 48px;
    .show-ui-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 16px;

      .chart-tick-container {
        display: flex;
        align-items: center;
        gap: 16px;

        .view-chart {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .chart-positions {
          position: relative;
          top: -2px;
          opacity: 1;
        }
      }

      .index-chart {
        img {
          position: relative;
          top: -2px;
        }
      }
    }

    iframe {
      height: 30vh !important;
    }
  }

  @media screen and (max-width: 700px) {
    iframe {
      height: 40vh !important;
    }
  }
}

.chart-pad-show-content {
  @media screen and (max-width: 1110px) {
    padding-bottom: calc(30vh + 55px) !important;
  }

  @media screen and (max-width: 700px) {
    padding-bottom: calc(40vh + 65px) !important;
  }
}

.close-container {
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: flex-end;
}

.rc-slider-track {
  height: 8px !important;
}

.rc-slider-rail {
  height: 8px !important;
}

.rc-slider-dot {
  width: 2px !important;
  height: 12px !important;
  top: -2px !important;

  &:first-child {
    left: 1.9px !important;
  }
}

.rc-slider-handle {
  top: 5px !important;
}

.trade-v1 {
  @media screen and (max-width: 1350px) {
    height: unset !important;

    .Exchange-content {
      height: unset !important;

      .Exchange-lists {
        padding-top: 16px;
      }

      .Exchange-list {
        .App-card-options {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
}

.exchange-v1 {

  // .Exchange-content {
  //   height: unset !important;
  // }
  // .Exchange-right {
  //   @media screen and (max-width: 1350px) {
  //     overflow-y: unset !important;
  //     max-height: unset !important;
  //   }
  // }
  .ExchangeChart-additional-info {
    div {
      font-weight: 700;
    }

    .ExchangeChart-info-label {
      font-weight: 400;
    }
  }

  .info-main-price {
    .ExchangeChart-info-label {
      font-weight: 700;
    }
  }

  .trade-version {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .ExchangeChart-top {
    @media screen and (max-width: 1350px) {
      padding-top: 12px !important;
      padding-bottom: 16px !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
    }

    @media screen and (max-width: 700px) {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }

  .Exchange-left {
    @media screen and (max-width: 700px) {
      padding-right: 8px !important;
      padding-left: 8px !important;
    }
  }

  .ExchangeChart-additional-info {
    gap: 4px;
    display: flex;
    flex-direction: column;
  }

  .ExchangeChart-top-inner {
    @media screen and (max-width: 1400px) {
      gap: 24px;
    }
  }

  .chart-token-menu-items.menu-items {
    width: 20rem;
    // top: 5rem;
    right: unset;
    left: 0;
    background: #242743;
    border-radius: 12px;
  }

  .ExchangeChart-top {
    display: flex;

    @media screen and (max-width: 1350px) {
      flex-direction: column;
      gap: 12px;

      .ExchangeChart-top-inner {
        gap: 0;
      }
    }
  }

  .Exchange-swap-box {
    .Exchange-swap-order-type-tabs {
      background-color: #212224;
      border-radius: 12px;
      display: flex;

      .Tab-option {
        border-radius: 8px;
        font-style: normal;
        margin-right: 0px;
        flex-direction: column;
        font-style: normal;

        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        color: var(--Text-Text_Secondary, #999691);
        font-size: 12px;
        font-weight: 700;
        line-height: 140%;
        /* 16.8px */
      }

      .active {
        color: white !important;
        border-radius: var(--Border-Radius-Medium, 12px);
        border: 2px solid #41454d;
        background: var(--Nature-3, #2e3033);
      }
    }
  }

  .Exchange-swap-section {
    border-radius: 12px !important;
    padding: 12px;
    background: rgba(0, 0, 0, 0.6);
    transition: all 150ms ease-in-out;
    /* box-shadow: inset 0 0 3rem 0.5rem hsl(0deg 0% 100% / 1%); */
  }

  .Exchange-swap-section:has(input:focus) {
    background: rgba(0, 0, 0, 1);
    outline: 1px solid #7ee55c;
  }

  .Exchange-swap-box-inner {
    .Exchange-info-row {
      .align-right {
        font-weight: 700 !important;
      }
    }
  }
}

.max-leverage-container {
  .chart-token-selector--current {
    width: 83px;
  }

  .token-selected {
    width: 24px;
    height: 24px;
  }
}

#webpack-dev-server-client-overlay {
  display: none;
}

.swap-box-swap-container {
  border-left: 1px solid rgba(255, 255, 255, 0.1);

  .swap-box-v2 {
    .Tab {
      margin-bottom: 1px !important;
      height: 44px;
    }
  }
}

.exchange-v1 {
  height: calc(100vh - 62px);

  .Exchange-swap-box {
    flex: 1;
    height: unset !important;
  }

  .Modal-content {
    .Tooltip-popup {
      width: 310px !important;
    }
  }

  @media screen and (max-width: 700px) {
    height: unset;
  }

  .Exchange-content {
    height: calc(100vh - 62px);

    @media screen and (max-width: 1350px) {
      height: calc(100vh - 62px);

      .Exchange-swap-box {
        height: calc(100vh - 62px);
      }
    }

    .box-trade-swap {
      height: calc(100vh - 62px);

      @media screen and (max-width: 1350px) {
        height: calc(100vh - 109px);
      }

      @media screen and (max-width: 700px) {
        height: unset;
      }
    }

    @media screen and (max-width: 700px) {
      height: unset;

      .Exchange-swap-box {
        height: unset;
      }
    }
  }

  .Exchange-left {
    overflow-y: auto;
    max-height: calc(100vh - 62px);

    @media screen and (max-width: 900px) {
      overflow-y: unset;
      max-height: unset;
    }
  }

  .Exchange-right {
    overflow-y: auto;
    border-left: rgba(255, 255, 255, 0.1) solid 1px;
    max-height: calc(100vh - 62px);

    @media screen and (max-width: 700px) {
      border: none;
      // margin-top: 16px;
      overflow-y: unset;
      max-height: unset;
    }
  }

  .ExchangeChart-top {
    .info-main-swap {
      @media screen and (max-width: 1350px) {
        top: 17px !important;
        right: 24px !important;
      }

      @media screen and (max-width: 700px) {
        top: 2px !important;
        right: 8px !important;
      }
    }
  }

  .Exchange-list-tabs {
    width: fit-content;
    border-radius: 4px;
    border: none;
    background: transparent;
    border-radius: 12px;

    .Tab-option {
      border-radius: 4px;
      padding: 12px 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      color: var(--white-60, var(--text-text-secondary, rgba(255, 255, 255, 0.6)));
      font-size: 12px !important;
    }

    .active {
      background: #2e3033 !important;
      color: var(--white-100, #fff) !important;
    }
  }
}

.Exchange-swap-button-container {
  button {
    height: 40px !important;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}

.swap-box-swap-container {
  .swap-box-v2 {
    padding-bottom: 14px;
  }
}

.Collateral-Modal {
  .Modal-title-bar {
    margin: 0;
  }
}

.ExchangeChart {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

// .App-slider .rc-slider-track {
//   background: #176CE5;
// }

// .App-slider .rc-slider-dot {
//   border: none;
//   border-radius: 1px;
//   width: 2px;
//   margin-left: -1px;
//   background: #101233;
// }

// .App-slider .rc-slider-dot-active {
//   background: #176CE5;
// }

.App-slider .rc-slider-handle {
  background-color: #191b2e;
  border: 2px solid #176ce5;
  border-radius: 50%;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  height: 18px;
  margin-top: -5px;
  position: absolute;
  touch-action: pan-x;
  width: 18px;
}

// .App-slider .rc-slider-handle:active {
//   box-shadow: 0 0 0.4rem 0.6rem #ffcd572c;
// }

// .App-slider .rc-slider-mark-text,
// .App-slider .rc-slider-mark-text-active {
//   color: white;
//   opacity: 0.5;
// }

// .App-slider .rc-slider-mark-text:hover,
// .App-slider .rc-slider-mark-text-active:hover {
//   opacity: 1;
// }

.rc-slider-tooltip {
  z-index: 5;
}

.rc-slider-tooltip-inner {
  box-shadow: none;
  padding: 0.465rem 0.8rem;
  background: #303652;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #303652;
}

.normalSlider {
  .rc-slider-rail {
    background: rgba(255, 255, 255, 0.1) !important;
    height: 4px !important;
  }

  .rc-slider-step {
    .rc-slider-dot {
      background: rgba(255, 255, 255, 0.1);
      top: 0px !important;
      width: 2px !important;
      height: 12px !important;
      top: -2px !important;

      &:first-child {
        left: 0px !important;
      }
    }

    .rc-slider-dot-active {
      background: #fff !important;
      border: none !important;
      width: 12px !important;
      height: 12px !important;
    }
  }

  .rc-slider-mark {
    top: 22px !important;
  }

  .rc-slider-mark-text-active {
    opacity: 1 !important;
  }

  .rc-slider-handle {
    top: 3px !important;
  }

  .rc-slider-track {
    height: 4px !important;
  }

  .rc-slider-mark-text {
    opacity: 0.6 !important;
  }
}

.scan-hide-tag {
  top: -2px !important;
}

.Exchange-left {
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.close-container {
  .menu-items {
    padding: 0px;
  }
}

.swap-v1-container {
  padding-top: 0 !important;

  .Exchange-lists.large {
    background-color: transparent !important;
  }

  @media screen and (max-width: 1350px) {
    .ExchangeChart-additional-info {
      width: 100% !important;
      justify-content: flex-end;
      display: flex;
      flex-direction: row;
      align-items: center;

      .ExchangeChart-info-label {
        width: unset !important;
      }
    }
  }
}

.Exchange-swap-section-bottom {
  .PositionEditor-token-symbol {
    font-weight: 500;
  }
}

.select-symbol-container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .supported-version {
    display: flex;
    gap: 4px;
    align-items: center;

    .title {
      color: var(--white-60, rgba(255, 255, 255, 0.6));
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      /* 12px */
    }

    .ver {
      display: flex;
      padding: 2px var(--Border_radius, 4px);

      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: var(--Border_radius, 4px);
      background: var(--white-10, rgba(255, 255, 255, 0.1));
      color: var(--white-60, rgba(255, 255, 255, 0.6));
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }
}

.trade-version {
  @media screen and (max-width: 1350px) {
    position: absolute !important;
    width: fit-content !important;
    right: 16px;
    top: 11px;
    height: fit-content !important;
  }
}

.max-leverage-container {
  @media screen and (max-width: 1350px) {
    padding: 8px !important;
  }
}

.trade-version {
  .version-tab {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 100% !important;
  }
}

.ExchangeChart-top {
  @media screen and (max-width: 1350px) {
    .trade-version {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      border: none;
    }

    .info-main-price {
      top: 15px !important;
      right: unset !important;
      left: 191px;
    }
  }

  @media screen and (max-width: 767px) {
    .info-main-price {
      top: 14px !important;
      right: unset !important;
      left: 177px;
    }

    .trade-version {
      right: 8px !important;
      top: -1px !important;
    }
  }
}

.ExchangeChart-top {
  height: 76px !important;

  @media screen and (max-width: 1350px) {
    height: unset !important;
  }
}

.apr-token-group {
  display: flex;
  gap: 4px;
}

.Exchange {
  .Modal-body {
    .Tooltip-popup {
      max-width: 340px;
      text-wrap: unset !important;
    }
  }
}

.swap-v1-container {
  .Exchange-swap-box-info {
    gap: 0;

    .Exchange-info-row {
      height: 20px;

      .Tooltip-handle {
        color: #999691;
      }
    }
  }
}

.Exchange-swap-box-inner,
.item-section-container {
  .small-text-number {
    font-size: 14px;
    font-weight: bold;
    margin-right: 4px;
  }
}

.spot-arregator-container {
  .price-value {
    .small-text-number {
      font-size: 16px;
      font-weight: bold;
      // margin-right: 4px;
    }
  }
}

.positionHistory-modal {
  .Modal-content {
    overflow-y: auto !important;
  }
}

.spot-aggregator-wrapper {
  @media screen and (max-width: 1350px) {
    .Exchange-updated-ui .Exchange-content .ExchangeChart {
      height: 49.6rem !important;
      border-radius: 0px !important;
    }

    .ExchangeChart.tv .ExchangeChart-bottom {
      top: 80px !important;
      left: 24px;
      right: 24px;
      bottom: 24px;
    }
  }

  @media screen and (max-width: 700px) {
    .Exchange-updated-ui .Exchange-content .ExchangeChart {
      height: 30rem !important;
    }

    .ExchangeChart.tv .ExchangeChart-bottom {
      top: 96px !important;
      left: 16px;
      right: 16px;
      bottom: 16px;
    }
  }

  .ExchangeChart-bottom-controls {
    position: relative;
    z-index: 3;
  }
}

.label-box-container {
  display: flex;
  align-items: center;
  height: 44px;
  background-color: #212224;
  border-radius: var(--Border-Radius-Medium, 12px);

  .label-box-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    /* 19.6px */
    color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
    text-decoration: none;

    display: flex;
    width: 108px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: var(--Base-Unit-XS-8, 8px);
    height: 100%;
  }

  .label-box-title--active {
    color: #fff !important;
    cursor: no-drop;

    border-radius: var(--Border-Radius-Medium, 12px);
    border: 2px solid #4d4544;
    background: var(--Nature-3, #332e2d);
  }

  .box-divider {
    width: 1px;
    height: 24px;
    background: var(--Border, rgba(255, 255, 255, 0.1));
  }
}

.theme--light {
  .label-box-container {
    .label-box-title {
      color: rgba(13, 26, 22, 0.7) !important;
    }

    .label-box-title--active {
      color: #02b27f !important;
    }

    .box-divider {
      background: rgba(13, 26, 22, 0.07);
    }
  }
}

@media screen and (max-width: 700px) {
  .swap-arregator-box {
    padding: 16px !important;
  }

  .label-box-title {
    padding: 12px 10px !important;
    width: 100px !important;
  }

  .swap-box-swap-container {
    .Exchange-swap-box-inner {
      padding: 16px !important;
    }
  }
}

.Exchange-swap-option-tabs {
  background: #212224;
}

.exchange-v2 {
  @media screen and (max-width: 900px) {
    .ExchangeChart {
      padding-top: 24px !important;
    }

    .info-main-price {
      left: 16px;
      top: 49px;
    }
  }

  @media screen and (max-width: 768px) {
    .exchange-chart-info-container {
      margin-top: 0px !important;
    }
  }

  @media screen and (max-width: 1350px) {
    .ExchangeChart-top {
      padding: 0px 8px !important;
    }

    .ExchangeChart {
      padding-top: 16px !important;
    }

    .ExchangeChart-top-inner {
      padding-left: 0;
    }

    .ExchangeChart-title-container {
      margin-right: 0 !important;
    }
  }

  @media screen and (max-width: 900px) {
    .info-main-price {
      top: 50px !important;
    }
  }

  @media screen and (max-width: 767px) {}
}

.exchange-v2 {
  @media screen and (max-width: 1620px) {
    .ExchangeChart-top {
      display: flex !important;
      // overflow: auto;
      // overflow-y: hidden;
      width: 100%;

      &::-webkit-scrollbar {
        width: unset !important;
        height: 6px !important;
      }

      .ExchangeChart-top-inner {
        flex: 1;
        width: unset !important;
      }
    }

    .trade-version {
      width: 105px;
    }

    .exchange-chart-info-container {
      overflow: visible !important;
      width: unset !important;
    }
  }
}

.exchange-v1 {
  .PositionsList {
    .Tab {
      .Tab-option {
        color: #919499;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      .active {
        color: white;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .Exchange-left {
      padding: 0px 16px !important;

      .ExchangeChart {
        padding-top: 16px;
      }
    }
  }
}

.App .Exchange-lists {
  border-radius: 16px !important;
  background: var(--Nature-1, #18191a) !important;

  &.Exchange-swap {
    background: transparent !important;

    @media screen and (max-width: 700px) {
      padding: 0 !important;
    }
  }

  @media screen and (max-width: 700px) {
    margin: 16px;
  }
}

.exchange-v2 {
  .Exchange-lists {
    @media screen and (max-width: 700px) {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }

  .Exchange-content {
    @media screen and (max-width: 700px) {
      padding: 16px !important;
    }
  }
}

.exchange-v1 {
  .Exchange-content {
    @media screen and (max-width: 900px) {
      padding: 16px !important;
    }

    @media screen and (max-width: 700px) {
      padding: 0px !important;
    }
  }

  .Exchange-left {
    // @media screen and (max-width: 900px) {
    //   margin-bottom: 16px;
    // }
  }
}

.Exchange-right {
  @media screen and (max-width: 700px) {
    margin-left: 0px !important;
  }
}

.divider {
  background: var(--Border, rgba(239, 236, 235, 0.08));
  height: 1px;
  width: 100%;
  margin: 12px 0 12px 0;
}

.table-content-invalid {
  td {
    padding-bottom: 40px !important;
  }

  .close-container-td {
    width: 202px !important;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }

  .order-info {
    opacity: 0.5;
  }

  position: relative !important;

  .error-order {
    position: absolute !important;
    left: 12px;
    top: 55px;
  }
}

.linked-order-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .linked-title {
    span {
      font-size: 14px;
    }
  }

  .linked-list-order {
    display: flex;
    gap: 4px;
    flex-direction: column;
    font-size: 14px !important;
  }

  .linked-order-content {
    display: flex;
    padding: 12px;
    flex-direction: column;
    gap: 12px;
    border-radius: var(--Border-Radius-Medium, 12px);
    background: var(--Nature-1, #18191a);

    .linked-list-order {
      &:last-child {
        .divider {
          display: none !important;
        }
      }
    }
  }
}
