@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.bold {
  font-weight: bold !important;

  &.white {
    color: #fff;
  }
}

.App {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  font-size: var(--font-base);
}

.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-content header {
  position: relative;
  z-index: 800;
}

.new-dot-mobile {
  left: 55px !important;
}

.new-dot {
  position: absolute;
  width: 6px;
  height: 6px;
  left: 8px;
  top: 14px;
  border-radius: 100px;
  background: #176ce5;

  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: -2px;
    left: -2px;
    z-index: 1;
    animation: pulsate 1.1s ease-out infinite;
    background: #c1ff99b3;
  }
}

.App-header.large {
  height: 6.2rem;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  background: transparent 0% 0% no-repeat padding-box;
  border-bottom: 1px solid var(--Border, rgba(239, 236, 235, 0.08));
}

.App-header.large::after {
  height: 1.3rem;
  width: 100%;
  content: " ";

  position: absolute;
  bottom: -1.3rem;
  left: 0;
}

.App-header.large .App-header-links {
  display: flex;
}

.App-header.small {
  display: none;
}

.App-header-link-home {
  display: none;
}

.App-header-drawer {
  // background: #17182c88 0% 0% no-repeat padding-box;
  // box-shadow: 0.8rem 0.3rem 0.6rem #00000029;
  // backdrop-filter: blur(2.7rem);
  background: #141312;
  opacity: 1;
  position: fixed;
  z-index: 801;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 30.4rem;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  max-width: 100%;

  .App-header-link-main {
    svg {
      display: inline-block !important;
    }
  }
}

.App-header-drawer::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.App-header-link-container {
  display: flex;
  align-items: center;
  position: relative;
  // border-bottom: 0.5px solid var(--nature-3, #232121);

  a {
    width: 100%;
  }
}

.App-header-drawer .App-header-link-container .a,
.App-header-drawer .App-header-link-container a {
  font-size: var(--font-sm);
  line-height: 1.8rem;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #ffffff99 !important;
  padding: 1.5rem 1.6rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 60px;
}

.App-header-drawer .App-header-link-container .a:hover,
.App-header-drawer .App-header-link-container .a:focus,
.App-header-drawer .App-header-link-container .a:active,
.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: #303fd024;
  color: white;
}

.App-header-links-header {
  height: 6.2rem;
  display: flex;
  align-items: center;
  z-index: 3;
  margin-left: 1.6rem;
  justify-content: space-between;
}

.App-header-container-left {
  display: flex;
  align-items: center;
}

.App-header-top {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* z-index: 2; */
}

.App-header-menu-icon-block {
  margin-right: 1.2rem;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.App-header-menu-icon {
  color: white;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 0.9rem 1rem;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

.App-highlight-box {
  background: linear-gradient(
    45deg,
    rgba(80, 10, 245, 1) 0%,
    rgba(43, 118, 224, 1) 35%,
    rgba(7, 157, 250, 1) 77%,
    rgba(2, 207, 207, 1) 100%
  );
  border-radius: 0.3rem;
  box-shadow: 0 0 0.4rem 0.6rem rgba(45, 66, 252, 0.15);
}

.App-card {
  padding: 1.5rem 1.5rem 1.86rem;
  // border: 1px solid #ffffff10 !important;
  border-radius: 0.4rem;
  font-size: var(--font-base);
  // background-color: #ffffff10 !important;
  background: #212224;
  // background: #212333 !important;
  border-radius: 16px !important;
}

.App-box,
.App-card,
.App-card-primary,
.App-box-highlight,
.App-box-solid {
  position: relative;
  // border: 1px solid #ffffff10;
  border-radius: 16px;
  // background: #ffffff10;
  font-size: var(--font-base);
}

.TokenSelector {
  .Modal {
    padding: 12px 16px;

    .Modal-content {
      position: relative;
      // &:after {
      //   content: "";
      //   background-color: white;
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   right: 0;
      //   height: 24px;
      // }
    }
  }

  .TokenSelector-box {
    font-size: 13px;
    font-weight: 700;
    line-height: 140%;
  }

  .TokenSelector-box-symbol {
    margin-right: 4px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    .TokenSelector-caret {
      path {
        fill: #4787e3 !important;
      }
    }
  }
}

.Exchange-swap-max {
  top: 0;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-card-bottom-placeholder {
  visibility: hidden;
}

.App-card-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  padding-bottom: 1.8rem;
}

.App-card-bottom .App-card-divider,
.App-card-bottom-placeholder .App-card-divider {
  margin-bottom: 1.8rem;
}

.App-card-long {
  background: #16182e;
  margin-bottom: 2.4rem;
}

.App-card-long-content {
  display: flex;
  padding: 1.6rem 0;
}

.App-card-long_sub {
  width: 20%;
  padding: 0 1.6rem;
}

.App-card-long_sub__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.8rem;
}

.App-card-long_sub__info___title {
  font-size: var(--font-md);
  line-height: 2.1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-long_sub__info___subtitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #a0a3c4;
}

.App-card-long-sub-left {
  display: flex;
}

.App-card-long_sub-icon {
  display: flex;
}

.App-card-long_sub__iconlist .App-card-long_sub__iconlist___icon {
  margin-left: 0.8rem;
}

.App-card-long_sub__iconlist .App-card-long_sub__iconlist___icon img {
  filter: grayscale(1);
}

.App-card-long_sub__iconlist .App-card-long_sub__iconlist___icon img:hover {
  filter: unset;
}

.App-card-long_sub__iconlist {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.App-card-divider-vertical {
  width: 1px;
  height: 8.8rem;
  background: #a0a3c4;
}

.App-card-long_sub:first-child {
  display: flex;
  align-items: center;
}

.App-card-long_sub__title {
  color: #a0a3c4;
  font-size: var(--font-sm);
  line-height: 1.8rem;
  font-weight: normal;
  letter-spacing: 0.25px;
  margin-bottom: 0.8rem;
}

.App-card-long_sub__subtitle {
  letter-spacing: 0.36px;
  color: white;
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: normal;
}

.App-card-title-block {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
}

.App-card-title-info-icon {
  display: flex;
  margin-right: 0.8rem;
}

.App-card-title-info {
  display: flex;
  align-items: center;
}

.App-card-info-title {
  font-size: var(--font-md);
  letter-spacing: 0px;
  color: #ffffff;

  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #aca4a4;
}

.App-card-info-subtitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}

.App-card-title-iconlist {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.8rem;
}

.App-card-title-iconlist___icon img {
  filter: grayscale(1);
}

.App-card-title-iconlist___icon img:hover {
  filter: unset;
}

.Wallet-btn {
  width: 100%;
  height: 60px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
}

.Wallet-btn:hover {
  background: var(--dark-blue-hover);
  border-color: var(--dark-blue-border);
}

.Wallet-btn:active {
  background: var(--dark-blue-active);
}

.Wallet-btn:not(:last-child) {
  margin-bottom: 8px;
}

.Wallet-btn:last-child {
  margin-bottom: 12px;
}

.MetaMask-btn img {
  display: inline-block;
  height: 36px;
  margin-left: 0.15rem;
}

.CoinbaseWallet-btn img {
  display: inline-block;
  height: 36px;
}

.WalletConnect-btn img {
  display: inline-block;
  height: 36px;
}

button.App-connect-wallet {
  cursor: pointer;
  display: inline-block;
  border: none;
  font-size: var(--font-sm);
  line-height: 2rem;
  font-weight: 500;
  padding: 0.9rem 2.4rem;
  height: 3.6rem;
  color: #17182c;
  background: #03cfcd 0% 0% no-repeat padding-box;
  border-radius: 0.4rem;
}

button.App-connect-wallet:hover {
  opacity: 1;
}

.App-header-trade-link {
  // margin-right: 12px;
  border-radius: 12px;
}

.App-header-user-address {
  height: 40px;

  display: inline-flex;
  align-items: center;
  color: white;
  position: relative;
  padding: 0 8px 0 16px;
  border-radius: 12px;
}

.App-header-link-container {
  .App-header-dropdown {
    &:hover {
      .show-dropdown-link {
        visibility: visible;
      }
    }

    & > span {
      position: relative;

      .show-dropdown-link {
        visibility: hidden;
        position: absolute;
        top: 19px;
        z-index: 99999;
        padding-top: 8px;
      }

      .menu-items {
        position: static;

        .menu-item {
          &.actived {
            .wrap {
              & > span:not(.desc) {
                color: #176ce5;
              }
            }
          }
        }
      }
    }
  }
}

.App-header-dropdown {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  padding: 0px 1.7rem;
  height: 100%;

  @media screen and (max-width: 1400px) {
    padding: 0px 1.2rem;
  }

  .menu-selected {
    color: #176ce5 !important;

    path {
      fill: #176ce5 !important;
    }
  }

  span {
    font-weight: 500;
    line-height: 140%;
    top: -1px;
  }

  &:hover {
    color: #176ce5;

    path {
      fill: #176ce5;
    }
  }

  .menu-items {
    left: 0;
    right: unset;

    a {
      padding: 4px 0;
    }
  }

  img {
    width: 16px;
    height: 16px;
    position: relative;
    top: -1px;
    left: -2px;
  }

  svg {
    position: relative;
    top: 3px;
  }
}

.mobile-menu-divider {
  height: 1px;
  background: #4e516a;
}

.App-card-divider {
  height: 1px;
  // background: #23263b;
  background: rgba(255, 255, 255, 0.1);
  margin: 1.05rem -1.5rem;
}

.App-box-solid {
  background: linear-gradient(90deg, rgba(20, 21, 38, 1) 0%, rgba(25, 27, 47, 1) 100%);
}

.Page {
  max-width: 108.5rem;
  margin: auto;
  padding-top: 4.65rem;
}

.Page-content {
  padding: 4.65rem;
  padding-top: 1.5rem;
}

.section-title-block {
  display: flex;
  margin-bottom: 2.325rem;
  max-width: 58.4rem;
  width: 100%;
}

.page-main-content .section-title-block {
  margin-top: 6.2rem;
}

.section-title-icon {
  /* margin-right: 1.2rem; */
  display: flex;
  align-items: center;
}

.section-title-icon img {
  /* display: flex; */
  display: none;
}

.section-title-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.section-title-content .section-title-content__title {
  font-size: var(--font-lg);
  line-height: 3.1rem;
  color: white;
  letter-spacing: 0px;
}

.section-title-content .section-title-content__description {
  font-size: 1.8rem;
  line-height: 2.3rem;
  letter-spacing: 0px;
  color: #a0a3c4;
  margin-top: 0.4rem;
  max-width: 62rem;
}

.section-title-content .section-title-content__description span {
  cursor: pointer;
  color: white;
}

.Page-title-section {
  padding-left: 4.65rem;
  padding-right: 1.5rem;
  margin-top: 1.5rem;
  position: relative;
}

.Page-title-section:first-child {
  margin-top: 0rem;
}

.Page-title {
  font-size: var(--font-xl);
  font-weight: 500;
  margin-bottom: 0.8rem;
  font-weight: bold;
}

.note-purchase {
  flex-direction: column;
  margin-top: -22px;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff99;
}

.Page-description a {
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;
  color: inherit;
}

.Page-description {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-decoration: none !important;
}

// .Page-description span:hover {
//   color: #fff;
//   opacity: 0.9;
// }
.Page-description span {
  display: inline-flex;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none !important;
}

.Page-description span img,
.Page-description a img {
  margin-left: 0.4rem;
}

.App-hero {
  position: relative;
  text-align: center;
  padding-top: 6.2rem;
  margin-bottom: 3.1rem;
}

.App-hero-primary {
  font-size: 4.65rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.App-note {
  text-align: center;
  font-weight: bold;
  font-size: var(--font-sm);
  padding: 0 3.1rem;
}

.App-card {
  padding: 1.5rem 1.5rem 1.86rem;
  // border: 1px solid #1e2136;
  // border-radius: 0.4rem;

  border-radius: 16px;
  font-size: var(--font-base);
}

.App-card-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-card-title-small {
  font-size: var(--font-md);
  margin-bottom: 1.5rem;
}

.App-card-content {
  display: grid;
  grid-row-gap: 0.8rem;
}

.solid-btn {
  border: none !important;
  padding: 12px 16px;
  background-color: #20233b;

  .btn-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-left: 8px;
  }

  img {
    width: 32px;
    height: 32px;
    max-height: unset;
    max-width: unset;
  }
}

.App-card-row {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1.5rem;
  padding: unset;

  .contain-tooltip {
    display: flex;
    align-items: center;

    .Tooltip {
      top: unset !important;
    }
  }
}

.App-card-row {
  @media screen and (max-width: 1300px) {
    .Tooltip.value-mobile-tooltip {
      grid-column: 1/3;
      margin-left: auto;
    }
  }
}

.App-card-row.inner {
  padding-left: 1.5rem;
}

.App-card-row .icon {
  vertical-align: baseline;
  margin-left: 0.31rem;
  position: relative;
  top: 2px;
}

.App-card-row > div:last-child {
  text-align: right;
}

.App-card-options {
  padding: unset;
  margin: -0.62rem;
}

.App-card-option {
  margin: 0.62rem;
}

.App-header a.App-header-link-main,
.App-header .App-header-link-main {
  font-size: 1.7rem;
  padding: 1.5rem;
  margin-left: -1.5rem;
  display: flex;
  margin-right: 1.2rem;
}

.App-header-link-main img {
  display: inline-block;
  height: 4rem;
  vertical-align: middle;
}

.App-header-link-main img.small {
  display: none;
}

.App-header .a,
.App-header a {
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
}

.App-header .a.active,
.App-header a.active {
  opacity: 1;
  color: #080715;
  border-radius: 12px;
}

// .App-header .a.active:not(.clickable),
// .App-header a.active:not(.clickable) {
//   pointer-events: none;
// }

.App-header .a:hover,
.App-header a:hover {
  opacity: 1;
  color: #fff;
  border-radius: var(--Border-Radius-Medium, 12px);
  background: var(--Nature-2, #212224);
}

.App-header-container-left .a,
.App-header-container-left a {
  padding: 8px 12px;
  display: block;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: #fff;

  @media screen and (max-width: 1400px) {
    padding: 1.8rem 1.2rem;
  }
}

.App-header-container-left .a.active,
.App-header-container-left a.active {
  border-radius: var(--Border-Radius-Medium, 12px);
  background: var(--Nature-2, #212224);
  color: #fff;
}

.Toastify {
  font-size: var(--font-sm);
}

.Toastify__toast {
  min-height: 4.65rem;
  cursor: default;
}

.Toastify__toast-body {
  padding: 0 0.465rem;
}

.Toastify-debug {
  margin-top: 0.465rem;
  font-size: 1.24rem;
  opacity: 0.7;
}

.Toastify-debug-button {
  display: inline-block;
  border-bottom: 1px dashed;
  cursor: pointer;
}

.Toastify-debug-content {
  margin-top: 4px;
  padding-bottom: 8px;
  max-width: 300px;
  overflow-x: auto;
}

.App-icon {
  vertical-align: middle;
  margin-bottom: 0.465rem;
}

.App-background-side-1 {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(45, 66, 252, 0.05) 0%, rgba(8, 8, 10, 0) 100%);
}

.App-background-side-2 {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 216, 240, 0.01) 51%, rgba(0, 216, 240, 0.03) 100%);
}

.App-background {
  z-index: 4;
  position: absolute;
  left: -100%;
  right: 0%;
  top: -20%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(80, 10, 245, 0.05) 0%, rgba(80, 10, 245, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-background-ball-1 {
  z-index: 3;
  position: absolute;
  left: 0%;
  right: 0%;
  top: -50%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(102, 56, 217, 0.05) 0%, rgba(56, 33, 105, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-background-ball-2 {
  z-index: 2;
  position: absolute;
  left: -50%;
  right: -150%;
  top: -30%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(7, 149, 250, 0.05) 0%, rgba(7, 149, 250, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-highlight {
  z-index: 0;
  position: absolute;
  left: 0%;
  right: -100%;
  top: -100%;
  bottom: 0%;
  pointer-events: none;
}

.App-title {
  text-align: center;
  font-size: 4.65rem;
  margin-top: 17vh;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.App-subtitle {
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 7.75rem;
  opacity: 0.9;
  padding: 0 1.5rem;
}

.App-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: var(--font-sm);
  margin-bottom: 0.465rem;
}

.App-info-label {
  opacity: 0.7;
}

.App-cta {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 1.5rem;
  border-radius: 0.3rem;
  border: none;
  font-size: var(--font-sm);
}

a.App-cta,
button.App-cta {
  color: #fff;
  text-decoration: none;
  background: #176ce5;
  /* box-shadow: 0 0 0.4rem 0.6rem rgba(45,66,252,0.15); */
  border-radius: 12px;
  font-weight: 700;
}

button.App-cta:disabled {
  background-color: #aeaeae !important;
  color: #545655 !important;
}

a.App-cta:hover,
// button.App-cta:hover:enabled {
//   background: #176CE5;
//   // box-shadow: 0 0 0.4rem 0.6rem rgba(61, 247, 191, 0.118);
// }

.App-cta.small {
  padding: 0.9rem 1.1rem;
}

a.App-cta.transparent,
button.App-cta.transparent {
  box-shadow: none;
  background: none;
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
  padding: 0;

  .arrow {
    position: relative;
    top: 0px;
  }

  .max-leverage-container {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #242743;
    border-radius: 12px;
    padding: 10px 16px;

    @media screen and (max-width: 700px) {
      padding: 10px 8px;

      .chart-token-selector--current {
        font-size: 14px;
      }
    }
  }

  .chart-token-selector--current {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #ffffff;
    position: relative;
    top: 1px;
    text-align: left;
  }

  .max-lerveage {
    padding: 0px 4px;
    background: rgba(255, 199, 0, 0.2);
    border-radius: 4px;
    // width: 30px;
    height: 16px;
    font-weight: 400;
    font-size: 12px;
    color: #ffc700;
    line-height: 130%;
  }
}

a.App-cta.transparent:hover,
button.App-cta.transparent:hover:enabled {
  color: white;
  box-shadow: none;
  background: none;
}

button.App-cta.option,
a.App-cta.option {
  padding: 0.8rem 1.5rem;
  font-size: var(--font-sm);
}

a.App-button-option,
button.App-button-option {
  cursor: pointer;
  letter-spacing: 0px;
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 36px;
  border: none;

  background: #176ce5;
  border-radius: 12px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
}

a.App-button-option:hover,
button.App-button-option:hover:enabled {
  color: #fff;
  background: #4787e3;
  // box-shadow: 0 0 0.4rem 0.6rem #b7e39933;
}

button.App-button-option:disabled {
  cursor: not-allowed;
}

.App-header-container-right {
  display: flex;
  align-items: center;
}

.App-header-user {
  display: flex;
  align-items: center;
  gap: 8px;
}

.App-header-user-settings {
  display: inline-flex;
  cursor: pointer;
  border: none;
  border-radius: 1.5rem;
  font-size: var(--font-base);
  background: none;
  color: white;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  align-items: center;
  border-radius: 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.App-settings-row {
  margin-bottom: 0.8rem;
  font-size: var(--font-base);
}

.App-settings .App-cta {
  margin-top: 1.5rem;
}

.App-slippage-tolerance-input-container {
  position: relative;
}

.App-slippage-tolerance-input {
  // border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  width: 100%;
  border-radius: 12px;
  background-color: #070707;
}

.App-slippage-tolerance-input-percent {
  position: absolute;
  right: 1.1rem;
  width: 3.1rem;
  top: 2.325rem;
  bottom: 0;
  text-align: right;
}

.Network-icon {
  vertical-align: middle;
  width: 1.85rem;
  height: 1.85rem;
}

.App-header-user-netowork {
  margin-right: 2.4rem;
}

.App-content {
  // position: relative;

  // background: radial-gradient(ellipse at top, rgb(18, 14, 68) 10%, transparent 80%),
  //   radial-gradient(ellipse at bottom, rgb(18, 14, 68) 10%, transparent 80%) !important;
  // background: #0c0d26;
  // background: linear-gradient(180deg, #040B08 0%, #04050B 100%);
  .videoTag {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    max-height: 800px;
    width: 100%;
  }

  // > video {
  //   z-index: 1;
  //   object-fit: cover;
  //   background-color: #000000;
  //   filter: brightness(30%) blur(4px);
  // }

  // @media screen and (min-width: 2048px) {
  //   &:has(.RouterTab) {
  //     height: 100vh;
  //   }
  // }
}

.App-footer {
  left: 0;
  right: 0;
  position: fixed;
  bottom: 5vh;
  text-align: center;
}

a.App-social-link {
  font-size: 2.635rem;
  color: white;
  opacity: 0.8;
}

a.App-social-link:hover {
  opacity: 0.9;
}

.App-input {
  text-align: center;
  display: inline-block;
  width: 90%;
  max-width: 46.5rem;
}

.App-input input {
  width: 100%;
}

.App-input-top {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 0.8rem;
  padding-left: 0.155rem;
  padding-right: 0.465rem;
}

.App-input-label {
  text-align: left;
  opacity: 0.9;
}

.App-input-max {
  text-align: right;
  opacity: 0.9;
  cursor: pointer;
}

.App-input-max:hover {
  opacity: 1;
}

.App-input-container {
  border: 2px solid rgba(48, 31, 130, 0.8);
  background: rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr auto;
}

.App-input-unit {
  text-align: right;
  padding: 1.25rem;
  font-size: 2rem;
}

button.App-button {
  text-align: center;
  display: inline-block;
  font-size: 1.7rem;
  width: 90%;
  max-width: 46.5rem;
}

.Toastify__toast--error {
  background: #333957;
  color: white;
  border-radius: 0.6rem;
}

.Toastify__toast--success {
  background: transparent linear-gradient(90deg, #19836c 0%, #177c66 100%) 0% 0% no-repeat padding-box;
  color: white;
  box-shadow: 0px 0.3rem 0.6rem #0000005c;
  border-radius: 0.4rem;
}

.Toastify__close-button {
  align-self: flex-start;
  border-radius: 0.4rem;
  width: 2.1rem;
  min-width: 2.1rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: none;
}

.Toastify__close-button:hover {
  background: #ffffff47 0% 0% no-repeat padding-box;
}

.Toastify__toast-body {
  font: normal normal normal 1.4rem/1.6rem Relative;
  line-height: 2rem;
}

.dropdown-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(2rem);
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

.positive {
  color: #34f5ae;
}

.positive-background {
  background: linear-gradient(45deg, rgba(10, 115, 75, 1) 0%, rgba(12, 154, 100, 1) 100%);
}

.negative {
  color: #f6475d !important;
}

.negative-background {
  background: linear-gradient(45deg, rgba(115, 30, 43, 1) 0%, rgba(174, 43, 63, 1) 100%);
}

.App-warning,
.App-warning a {
  color: #f037b5 !important;
}

.App-warning {
  padding: 0 3.1rem;
}

// .App-slider .rc-slider-rail {
//   width: 100%;
//   background-color: #101233;
//   // background: linear-gradient(90deg, rgba(30, 34, 61, 1) 0%, rgba(40, 45, 74, 1) 100%);
// }

// .App-slider .rc-slider-track {
//   background: #02f6af;
// }

// .App-slider .rc-slider-dot {
//   border: none;
//   border-radius: 1px;
//   width: 10px;
//   height: 10px;
//   margin-left: -1px;
//   background: #2E3033;
// }

// .App-slider .rc-slider-dot-active {
//   background: #02f6af;
// }

// .App-slider .rc-slider-handle {
//   background: #101234;
//   border: 2px solid #02f6af;
// }

// .App-slider .rc-slider-handle:active {
//   box-shadow: 0 0 0.4rem 0.6rem #02f6af33;
// }

// .App-slider .rc-slider-mark-text,
// .App-slider .rc-slider-mark-text-active {
//   color: white;
//   opacity: 0.5;
// }

.App-slider .rc-slider-mark-text:hover,
.App-slider .rc-slider-mark-text-active:hover {
  opacity: 1;
}

.rc-slider-tooltip {
  z-index: 5;
}

.rc-slider-tooltip-inner {
  box-shadow: none;
  padding: 0.465rem 0.8rem;
  background: #303652;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #303652;
}

.header-connect-btn {
  background: #3b43e3;
}

.header-connect-btn:hover {
  background: #6a70ea;
}

.header-connect-btn:focus {
  background: #7a7fec;
  border: 1px solid #3b43e3;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.header-connect-btn:active {
  background: #7a7fec;
}

.short-connect-btn {
  display: none !important;
}

.selector-backdrop {
  opacity: 1;
  height: 100vh;
  z-index: 2;
  backdrop-filter: blur(1.7rem);
  position: fixed;
  top: 0;
  width: 100%;
}

.Tab-title-section {
  padding: 3.1rem 0 0;
  margin-bottom: 2.4rem;
}

.Home-language-menu {
  display: flex;
  align-items: center;
}

@media (max-width: 1350px) {
  .long-connect-btn {
    display: none !important;
  }

  .App-header-user-address {
    // padding: 0 8px;
  }

  .short-connect-btn {
    display: flex !important;
  }

  // .App-header-trade-link {
  //   margin-right: 1.6rem;
  // }

  .App-header-user-netowork {
    margin-right: 1.6rem;
  }

  button.App-connect-wallet {
    margin-top: 0.465rem;
    margin-bottom: 0.465rem;
  }

  .App-header-link-home {
    display: block;
  }

  .App-cta.small {
    padding: 0.9rem 0.8rem;
  }

  .App-subtitle {
    margin-bottom: 4.65rem;
  }

  .App-header-user {
    padding-bottom: 0;
  }

  .App-header-container-right {
    display: flex;
    align-items: center;
  }

  .App-header-link-desktop {
    display: none;
  }

  .App-header {
    grid-template-columns: 1fr auto auto auto auto;
  }

  .App-header.large {
    display: none;
  }

  .App-header.small {
    // height: 6.2rem;
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 12px 16px;
    background: transparent 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #ffffff10;
  }

  .App-header.small::after {
    height: 1.3rem;
    width: 100%;
    content: " ";
    background: transparent linear-gradient(180deg, #00000030 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    bottom: -1.3rem;
    left: 0;
  }

  .Page-title-section {
    margin-top: 3.1rem;
  }

  a.App-header-user-account {
    margin-right: 0;
  }

  .App-card-long-content.card-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1000px) {
  .App-card-long-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 1.5rem;
  }

  .App-card-divider-vertical {
    display: none;
  }

  .App-card-long_sub {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .App-card-long-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .App-card-long-content.card-list {
    grid-template-columns: 1fr;
  }

  .App-card-long-content {
    grid-template-columns: 1fr;
  }

  .default-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 600px) {
  .App-header-link-main img.big {
    display: none;
  }

  .App-header-link-main svg {
    display: none;
  }

  .App-header-link-main img.small {
    display: inline-block;
    height: 48px;
  }
}

@media (max-width: 500px) {
  .header-connect-btn {
    margin-right: 0.4rem;
  }
}

@media (max-width: 450px) {
  // .App-header-trade-link {
  //   margin-right: 0.8rem;
  // }

  .App-header-trade-link a {
    padding: 0 0.8rem;
  }

  .App-header-user-netowork {
    margin-right: 0.6rem;
  }

  .App-header-menu-icon-block {
    margin-left: 0;
    margin-right: 0.4rem;
  }

  .mobile-cross-menu {
    margin-right: 1.2rem;
  }

  .App-header-menu-icon {
    margin: 0.4rem 0.5rem;
  }

  // .App-header.small {
  //   padding-left: 0.6rem;
  //   padding-right: 0.6rem;
  // }

  .Home-faqs-content {
    padding-left: 1.5rem;
  }

  .header-connect-btn,
  .header-connect-btn:active {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .header-connect-btn:focus {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }

  .App-header .App-header-link-main {
    padding: 0.155rem !important;
    margin-left: 0rem !important;
  }

  .App-header-trade-link.homepage-header {
    margin-right: 1.6rem;
  }
}

.walletconnect-modal__mobile__toggle a {
  color: #000;
}

.walletconnect-modal__base {
  max-height: 100%;
}

.walletconnect-qrcode__image {
  max-height: 50vh;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .App-header-drawer {
    background: #0e0f1f;
  }
}

.spin {
  animation: spin-animation infinite 1.2s linear;
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.swap-box-v2 {
  background: #18191a;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
}

.box-trade-long {
  background: #18191a;
}

.box-trade-swap {
  background: #18191a;
}

.box-trade-short {
  background: #18191a;
}

.exchange-section-first {
  margin-bottom: 52px;
}

.normalSlider {
  .rc-slider-rail {
    background: rgba(255, 255, 255, 0.1);
    height: 1px;
  }

  .rc-slider-step {
    .rc-slider-dot {
      background: rgba(255, 255, 255, 0.1);
      top: -3px;
    }

    .rc-slider-dot-active {
      background: #176ce5;
    }
  }

  .rc-slider-handle {
    top: 3px;
  }

  .rc-slider-track {
    height: 1px;
  }

  .rc-slider-mark-text {
    opacity: 0.6;
  }
}

.shortSlider {
  .rc-slider-rail {
    background: rgba(255, 255, 255, 0.1);
    height: 1px;
  }

  .rc-slider-step {
    .rc-slider-dot {
      background: rgba(255, 255, 255, 0.1);
      top: -3px;
    }

    .rc-slider-dot-active {
      background: #f6475d;
    }
  }

  .rc-slider-handle {
    top: 3px;
    border: 2px solid #f6475d;

    &:active {
      box-shadow: 0 0 0.4rem 0.6rem rgba(246, 2, 2, 0.2);
    }
  }

  .rc-slider-track {
    height: 1px;
    background: #f6475d;
  }

  .rc-slider-mark-text {
    opacity: 0.6;
  }
}

.square-trade-v2 {
  // border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  // padding: 12px;
  margin-top: 16px;

  .TokenSelector-box {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  .TokenSelector-caret {
    position: relative;
    top: -1px;
  }
}

.Exchange-leverage-box {
  margin-bottom: 0;
}

.exchange-v2 .Modal-body .Exchange-swap-button-container {
  padding: 16px 24px 24px 24px;
  //padding

  @media screen and (max-width: 767px) {
    padding: 16px;
  }
}

.exchange-v2 {
  .Confirmation-box-row {
    padding: 24px;
    margin-top: 0;
  }
}

.box-trade-v2 {
  .Exchange-swap-order-type-tabs {
    .active {
      color: #176ce5 !important;
    }

    .Tab-option {
      color: rgba(255, 255, 255, 0.6);
      padding: 0;
      margin-right: 24px;
      // font-size: 14px !important;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.box-trade-short {
  .Exchange-swap-order-type-tabs {
    .active {
      color: #f6475d !important;
    }
  }
}

.Exchange-leverage-slider-settings {
  svg {
    border-radius: 4px;
    width: 16px;
    height: 16px;
  }
}

.leverage-settings-short {
  .active {
    color: #f6475d !important;
  }
}

.app-box-v2 {
  // background: #191b2e;
}

.new-history {
  table {
    border-radius: 12px;
  }

  .tradeHistory-Header {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.PositionsList {
  table {
    border-collapse: separate;
    border-spacing: 0 12px;
    margin-top: -35px;

    .Exchange-list-header {
      position: relative;
      top: 11px;
    }

    .table-content {
      background: #212224;
      position: relative;
      height: 60px;

      .flag {
        position: absolute;
        border-radius: 0px 24px 24px 0px;
        width: 4px;
        height: 37px;
        left: 0;
        top: calc(50% - 37px / 2);
      }

      .Exchange-list-info-label {
        position: relative;
      }

      td {
        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }

        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }

  .Exchange-list-header {
    th {
      color: rgba(255, 255, 255, 0.6) !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 140% !important;
    }
  }
}

.border-btn {
  background: none !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  color: #ffffff !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-radius: 12px !important;
  height: 40px;

  &:has(a),
  &:is(a) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    a {
      text-decoration: none;
    }
  }

  &:not(:disabled):not(.disabled):hover {
    color: #176ce5 !important;

    box-shadow: none !important;
    border: 1px solid #176ce5 !important;

    &:enabled {
      color: #176ce5 !important;

      box-shadow: none !important;
      border: 1px solid #176ce5 !important;
    }
  }
}

.theme--light {
  .border-btn {
    border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
    color: #0d1a16 !important;

    &:not(:disabled):hover {
      border: 1px solid #02b27f !important;
      color: #02b27f !important;

      &:enabled {
        color: #02b27f !important;

        box-shadow: none !important;
        border: 1px solid #02b27f !important;
      }
    }

    &:active {
      border: 1px solid #02b27f !important;
      color: #02b27f !important;
    }

    span:hover {
      color: #02b27f !important;
    }
  }
}

.Exchange-list-tab-container {
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 16px;

    .Exchange-should-show-position-lines {
      text-align: left;
      justify-content: flex-start;
      margin-bottom: 8px;
    }
  }
}

.ExchangeChart-top-inner {
  @media screen and (max-width: 1350px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0;

    .max-lerveage {
      margin-bottom: 0;
    }

    div {
      &:first-child {
        width: 100%;
      }
    }

    .ExchangeChart-title {
      margin-bottom: 12px;
    }

    .chart-token-menu-items {
      width: 42.5rem !important;
    }
  }

  @media screen and (max-width: 900px) {
    .chart-token-menu-items {
      width: calc(100%) !important;
    }
  }

  @media screen and (max-width: 700px) {
    .chart-token-menu-items {
      width: calc(100vw - 48px) !important;
    }
  }
}
.exchange-v2 {
  .Exchange-content {
    .Exchange-right {
      @media screen and (max-width: 1350px) {
        margin-top: 12px;
      }
      @media screen and (max-width: 768px) {
        max-height: unset !important;
        div.Exchange-swap-box {
          width: 352px;
        }
      }
    }
  }
}

.Exchange-left {
  &::-webkit-scrollbar {
    display: none !important;
  }

  @media screen and (max-width: 1110px) {
    .ExchangeChart-bottom {
      top: 139px;
    }
  }
}

.Exchange-list-tab-container {
  @media screen and (max-width: 1110px) {
    display: flex;
    flex-direction: column-reverse;

    .Exchange-should-show-position-lines {
      text-align: left;
      justify-content: flex-start;
      margin-bottom: 16px;
    }
  }
}

.short-btn-default {
  background: #f6475d !important;
  color: #fff !important;
  font-weight: 700;

  &:hover {
    background: rgb(240 144 144) !important;
  }
}

.long-btn-default {
  background: #7ee55c !important;
  color: #000 !important;
  font-weight: 700;

  &:hover {
    background: #56d72b !important;
  }
}

.short-btn-v2 {
  background: #f6475d !important;
  color: #fff !important;
  font-weight: 700;

  &:hover {
    background: #f66275 !important;
  }
}

.long-btn-v2 {
  background: #7de55d !important;
  color: #000 !important;
  font-weight: 700;

  &:hover {
    background: #9fff80 !important;
  }
}

.chart-token-menu-items {
  @media screen and (max-width: 700px) {
    width: 18.3rem;
  }
}

.vault-v2 {
  background: #191b2e;
  border-radius: 16px;

  .active {
    color: #176ce5;
  }
}

.table-token-container {
  // padding-left: 24px;
  // padding-right: 24px;

  table {
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;

    thead {
      th {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    tbody {
      tr {
        td {
          background: #212333;

          &:first-child {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }

          &:last-child {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }

          .dropdown-arrow {
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }
}

.PositionSeller-token-selector {
  .PositionSelector-selected-receive-token {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
}

.DashboardV2-token-cards {
  .token-table-wrapper {
    .App-card-title {
      padding-left: 9px;
    }
  }
}

.tab-option-icon-first {
  margin-left: 0 !important;
  margin-right: 4px !important;
}

.tab-option-icon-last {
  margin-right: 0 !important;
  margin-left: 4px !important;
}

.tabFontWetght {
  font-weight: 500;
}

.StakeV2-cards {
  .App-card-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  }
}

.address-btn {
  @media screen and (max-width: 700px) {
    .user-avatar {
      margin-right: 4px;
    }

    padding-left: 6px !important;
    padding-right: 6px !important;

    .user-address {
      margin-right: 4px !important;
      margin-left: 0 !important;
      font-weight: 400;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.buy-token-info-container {
  display: flex;

  .buy-token-icon {
    width: auto;
    height: 64px;
    margin-right: 16px;
  }

  .buy-card-info {
    .title-name {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;
      margin-bottom: 8px;
    }

    .buy-desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 8px;
    }

    .buy-apr {
      color: rgba(255, 255, 255, 0.6);
    }

    .apr-percent {
      color: #176ce5;
    }
  }
}

.tradeHistory-Header {
  @media screen and (max-width: 1000px) {
    display: none !important;
  }
}

.no-emty-order {
  background: none !important;
}

.no-position {
  .no-position-card {
    margin-left: -11px;
    margin-right: -11px;
    background: none !important;
  }
}

.no-array-length {
  margin-top: -22px !important;

  .App-card {
    margin-bottom: 0 !important;
  }
}

.no-trade-history {
  margin-top: 10px;
}

.details-position-container {
  background: none !important;
  border: none !important;
  border-spacing: 0px 8px !important;
  border-collapse: separate !important;

  .table-head {
    & > th {
      padding: 0 16px !important;
      color: var(--Text-Text_Secondary, rgba(255, 255, 255, 0.6));
      font-size: 12px;
      font-weight: 400 !important;
      line-height: 140%;
    }
  }

  .row-item-body {
    height: 56px;
    // var(--Nature-1,#191b2e)

    & > td {
      padding: 12px 16px;

      &:last-child {
        height: 56px;
      }
    }
  }
}

.account-trade-history {
  background: none !important;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.gmy-card-icons {
  width: 32px;
}

.no-data-card {
  background: none !important;
}

.GlpSwap-token-list {
  .token-table {
    border-spacing: 0px 8px;
    border-collapse: separate;

    tbody {
      tr {
        td {
          background: #212333;

          &:first-child {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }

          &:last-child {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }
    }

    thead {
      th {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.7);
        padding-top: 10px;
        padding-bottom: 0;
      }
    }
  }
}

.referral-tab-container {
  .Tab {
    height: 52px;
    padding: 4px;
    background: #191b2e;
    border-radius: 16px;

    .Tab-option {
      padding: 12px 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.app-card-position {
  position: relative;

  .leverage-pad {
    position: absolute;
    right: 0;
    top: -43px;
    display: flex;
    gap: 4px;

    .tab-leveage {
      background: rgba(255, 199, 0, 0.2);
      border-radius: 4px;
      padding: 0px 4px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffc700;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
    }
  }
}

.App-content {
  .Tooltip {
    .Tooltip-popup {
      background: #2e3033 !important;
      border-radius: 8px !important;
      color: rgba(255, 255, 255, 0.6) !important;

      .Tooltip-row-value {
        color: white !important;
        font-weight: 700 !important;
      }

      .Tooltip-row-values {
        li {
          color: white !important;
          font-weight: 700 !important;
          text-align: right !important;
        }
      }
    }

    &.fit-content {
      .Tooltip-popup {
        width: fit-content !important;
        min-width: unset;
        text-wrap: nowrap;

        & > div {
          text-wrap: nowrap;
        }
      }
    }
  }

  .Tooltip:not(.excep) {
    .Tooltip-popup {
      div {
        &:last-child {
          color: rgba(255, 255, 255, 0.6) !important;
          font-weight: 400;
        }
      }

      width: 350px;

      @media screen and (max-width: 700px) {
        width: 310px;
      }
    }
  }
}

.BuyGMYGLP-container {
  .token-price {
    .Tooltip-popup {
      @media screen and (max-width: 700px) {
        max-width: 230px !important;
      }
    }
  }
}

.Modal-content {
  background: #212224;
  border-radius: 16px !important;
  overflow-y: hidden !important;

  .Exchange-swap-section {
    background: rgba(0, 0, 0, 0.8) !important;
  }

  .Modal-body {
    padding: 24px !important;
  }

  .CompoundModal-menu {
    margin-bottom: 24px;

    div {
      margin-top: 12px;

      .Checkbox-label {
        font-size: 14px;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .Exchange-swap-button-container {
    button {
      height: 40px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px !important;
    }
  }
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  width: 100%;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.05);
  color: transparent !important;

  > * {
    display: none;
  }

  &::after {
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.05) 20%, rgba(#fff, 0.1) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.DashboardV2 {
  @media screen and (max-width: 600px) {
    .stats-value,
    .stats-info,
    .token-price {
      .Tooltip-popup {
        left: -50px;
        right: unset;
        width: 300px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .token-price {
      .Tooltip-popup {
        left: 0px;
        right: unset;
        width: 300px;
      }
    }
  }
}

.PositionSeller-modal {
  .Modal-body {
    padding: 24px 7px 0 0 !important;
  }
}

.PositionEditor {
  .Modal-body {
    padding: 24px 7px 0 0 !important;
  }

  .muted {
    font-weight: 400 !important;
    line-height: 16.8px !important;
  }

  .Tab {
    background: transparent !important;
    border-radius: 12px !important;
    background: #2e3033 !important;

    // border: 1px solid rgba(255, 255, 255, 0.1);
    .Tab-option {
      color: #919499;
      border: 2px solid transparent;
    }

    .active {
      border-radius: var(--Border-Radius-Medium, 12px);
      border: 2px solid #41454d;
      color: #fff;
      background: var(--Nature-3, #2e3033) !important;
    }
  }
}

.br-mobile {
  display: none;

  @media screen and (max-width: 700px) {
    display: inline;
  }
}

.mobile-token-card {
  .asset-menu-items {
    @media screen and (max-width: 700px) {
      transform: translate(-90%, 5px);
    }
  }
}

.Confirmation-box {
  .Tooltip {
    .Tooltip-popup {
      width: 300px;
    }
  }
}

.PositionsList {
  @media screen and (min-width: 1110px) {
    .Tooltip {
      .Tooltip-popup {
        min-width: 350px !important;
        width: fit-content !important;
      }
    }
  }
}

.value-apr-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.nft-notavailable-outside {
  min-height: 30vh;

  @media screen and (max-width: 700px) {
    min-height: 15vh;
  }
}

.nft-notavailable {
  // min-height: 30vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 20px !important;
    margin-bottom: 16px !important;
    font-weight: 600 !important;

    @media screen and (max-width: 700px) {
      font-size: 14px !important;
    }
  }

  .button-group {
    display: flex;
    gap: 12px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      width: 100%;

      button {
        width: 100% !important;
      }
    }
  }
}

.Modal.Connect-wallet-modal-v2 {
  z-index: 8888 !important;

  .Modal-title-bar {
    margin: 24px;
  }

  .Modal-content {
    background-color: #18191a;
    border-radius: 16px !important;
    max-width: 360px;

    .Modal-body {
      border: none;
      padding: 0 24px 24px !important;

      .wallet-desc {
        color: rgba(255, 255, 255, 0.6);
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 12px;
      }

      .learn-to-connect {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #176ce5;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        text-decoration: none;
      }
    }
  }
}

.wcm-overlay.wcm-active {
  z-index: 999 !important;
}

.nft-tool-tip {
  .Tooltip-popup {
    width: fit-content !important;
    min-width: unset !important;

    .nft-line {
      display: flex !important;
      white-space: nowrap !important;
      color: white !important;
      opacity: 1 !important;
    }
  }
}

.ant-tooltip-inner {
  background: #2e3033 !important;
}

.ant-tooltip-arrow {
  display: none !important;
}

.Collateral-Modal {
  .Modal-title-bar {
    padding: 16px 24px;

    .Modal-title {
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      line-height: 140%;
      text-align: left;
    }
  }

  .Modal-content {
    max-width: 424px;
  }

  .Modal-body {
    padding: 16px 24px 24px !important;
  }

  .Modal-description {
    font-size: 14px;
  }

  .Exchange-swap-section--v2 {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 12px;
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 12px;
  }

  .PositionEditor-token-symbol {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-align: right;
  }

  .Exchange-swap-button-container {
    padding: 0;
  }
}
.Exchange-swap-button-container {
  button {
    width: 100%;
  }
}
.transparent-ant-select-selection-item {
  .ant-select-selection-item {
    line-height: 22px !important;
  }

  .ant-select-selector {
    height: 22px !important;
    padding-left: 8px !important;
    padding-right: 0 !important;

    border-color: transparent !important;
  }

  .ant-select-arrow {
    inset-inline-end: 0 !important;
  }
}

.transparent-ant-select-selection-item {
  .ant-select-selection-item {
    line-height: 22px !important;
  }

  .ant-select-selector {
    height: 22px !important;
    padding-left: 8px !important;
    padding-right: 0 !important;

    border-color: transparent !important;
  }

  .ant-select-arrow {
    inset-inline-end: 0 !important;
  }
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topRight {
  // top: 0 !important;
  padding-bottom: 0 !important;
}

.green-value {
  color: #7ee55c !important;
}

.red-value {
  color: #f6475d !important;
}

.negative {
  color: #f6475d !important;

  .Tooltip-handle {
    color: #f6475d !important;
  }
}

.positive {
  color: #7ee55c !important;

  .Tooltip-handle {
    color: #7ee55c !important;
  }
}

.network-dropdown-items {
  width: 216px !important;
}

.menu-item-active {
  background: var(--white-10, rgba(255, 255, 255, 0.1)) !important;
}

.Modal-body {
  .network-dropdown-items {
    width: 100% !important;
  }
}

.App-header-container-left {
  .menu-items {
    min-width: 338px !important;
    width: 338px !important;
    padding: 8px;

    .menu-item {
      padding: 12px;

      &:hover {
        background: var(--v-3-natural-1, #191b2e);
      }

      .inner:has(.tag-v2) {
        display: flex;
        align-items: center;
        gap: 4px;

        .tag-v2 {
          display: flex;
          padding: 2px var(--Border_radius, 4px);
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: var(--Border_radius, 4px);
          border: 1px solid var(--L3, #f0b90b);

          text-align: center;
          font-size: 10px;
          font-weight: 800;
          line-height: 100%;
          /* 10px */
          text-transform: capitalize;
          color: #f0b90b;
        }
      }

      a {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 0;
      }

      img {
        width: 40px;
        height: 40px;
        display: flex;
        padding: var(--base-unit-xs-8, 8px);
        align-items: flex-start;
        gap: 10px;
        border-radius: var(--base-unit-s-12, 12px);
        background: var(--white-10, rgba(255, 255, 255, 0.1));
      }

      .wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      & > *:not(.desc) {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%;
        /* 19.6px */
      }

      .desc {
        color: var(--white-60, rgba(255, 255, 255, 0.6));
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
      }
    }
  }
}

.App-header-balance-container {
  .network-dropdown-seperator {
    height: 20px;
  }
}

.network-account-container {
  .App-header-network {
    margin-left: 0px;
    border: none;

    .network-dropdown {
      height: 100%;

      & > button {
        height: 100%;
      }
    }
  }

  .App-header-user-address {
    margin: 0 !important;
    border: none;
  }

  .network-dropdown-seperator {
    height: 20px;
  }
}

.App-header-user-address {
  .menu-item {
    color: white;

    &:hover {
      color: white;
    }
  }
}

.wrong-modal {
  .Modal-content {
    width: 100%;
    max-width: 424px;
    margin-left: 16px;
    margin-right: 16px;
  }
}

.App-header-user-address,
.network-dropdown-list {
  .menu-item,
  .menu-item-icon {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.network-dropdown-items {
  .menu-item-icon {
    width: 24px;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.no-array-length-position {
  margin-top: -11px !important;
}

.no-array-length-history {
  margin-top: 10px !important;
}

.App-header-link-container {
  .App-header-dropdown {
    display: flex;
    align-items: center;

    > div {
      min-height: 100%;
      display: flex;
      align-items: center;
      // margin-top: 0 !important;
    }
  }
}

.network-dropdown {
  .network-dropdown-icon {
    width: 24px;
    height: 24px;
  }
}

.custom-tooltip {
  font-size: var(--font-sm);
  line-height: 140%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #33302e;
  border-radius: 8px;
  padding: 16px;

  .data-row,
  .date {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .label {
    color: white;
    font-weight: 500;
  }
}

.csv-link {
  transition: scale 0.3s;

  &:hover {
    cursor: pointer;
    scale: 1.2;
    transition: scale 0.3s;
  }
}

.react-dropdown-select-dropdown {
  background: #11121b;
  border: 1px solid #11121b;

  .rdrDayStartPreview,
  .rdrDayEndPreview {
    border: 1px solid #02b27f;
  }

  .rdrDayInPreview {
    border: 1px solid #02b27f;
    border-right: none;
    border-left: none;
  }

  @media screen and (max-width: 767px) {
    right: -165px !important;
  }
}

.recharts-layer {
  text {
    fill: #fff;
  }
}

.ant-select {
  border: 1px solid var(--Border, rgba(13, 26, 22, 0.07)) !important;
  border-radius: 4px;
}

.App-header-dropdown {
  &:hover {
    .arrow-icon-tab-header {
      path {
        fill: #00b17c !important;
      }
    }
  }
}

.theme--light {
  // .default-btn {
  //   background: var(--Primary, #02b27f);
  //   color: #fff !important;

  //   &>span {
  //     color: #fff !important;
  //   }

  //   &:not(:disabled):hover {
  //     background: #03805b !important;
  //   }

  //   &:not(:disabled):active {
  //     background: #03805b !important;
  //   }
  // }

  .TokenSelector {
    &:hover {
      .TokenSelector-caret {
        path {
          fill: #02b27f !important;
        }
      }
    }
  }
}

.PositionSeller-receive-row {
  @media screen and (max-width: 900px) {
    .Modal-body {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }

    .Modal-content {
      padding-bottom: 16px !important;
    }
  }
}

.gray-divider {
  width: 100%;
  height: 1px;
  background-color: #efeceb14;
  margin-bottom: 16px;
}

.TokenSelector {
  @media screen and (max-width: 700px) {
    .Modal {
      .Modal-content {
        width: calc(100vw - 32px) !important;
      }
    }
  }
}

.earn-content-container {
  .right-content-border-box {
    .box-token-info {
      @media screen and (max-width: 700px) {
        .Tooltip-popup {
          width: 262px !important;
        }
      }
    }
  }
}

.modal-mint {
  .PositionDropdown-dots-icon {
    img {
      top: 13.5px !important;
    }
  }
}

.Toastify {
  .Toastify__toast-container {
    z-index: 9999999999;
    width: 308px !important;
  }

  @media screen and (max-width: 767px) {
    .Toastify__toast-container {
      width: 100% !important;
    }
  }

  .Toastify__toast {
    padding: 16px;
    border-radius: 12px;
  }

  .Toastify__toast-body {
    align-items: start;
    padding: 0 !important;
  }

  .Toastify__close-button {
    path {
      fill: rgba(255, 255, 255, 0.6);
    }
  }

  .Toastify__toast--info {
    background: linear-gradient(90deg, #0a2462 0%, #010610 100%);
  }

  .Toastify__toast--success {
    background: linear-gradient(90deg, #043e30 0%, #010d0a 100%);
  }

  .Toastify__toast--warning {
    background: linear-gradient(90deg, #3e2e04 0%, #120d01 100%);
  }

  .Toastify__toast--error {
    background: linear-gradient(90deg, #340906 0%, #0e0202 100%);
  }

  .Toastify__progress-bar--info {
    background: #366ae2;
  }

  .Toastify__progress-bar--success {
    background: #7ee55c;
  }

  .Toastify__progress-bar--warning {
    background: #ffb200;
  }

  .Toastify__progress-bar--error {
    background: #f6475d;
  }

  .toastify-container {
    display: flex;
    align-items: start;
    gap: 8px;

    .toastify-group {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    span {
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .status-icon-wrapper {
      width: 20px;
      height: 20px;
      padding: 0 !important;

      &.success {
        path {
          fill: #7ee55c;
        }
      }

      &.error {
        path {
          fill: #f6475d;
        }
      }

      &.info {
        path {
          fill: #366ae2;
        }
      }

      &.warn {
        path {
          fill: #ffb200;
        }
      }
    }

    .link-underline {
      text-decoration: none !important;
    }

    .toastify-content-wrapper {
      color: rgba(255, 255, 255, 0.6);

      a {
        color: #7ee55c;

        span {
          font-weight: 400 !important;
          color: #7ee55c;
        }

        &:hover {
          color: #56d72b;

          span {
            color: #56d72b;
          }
        }
      }

      .connect-error-wrapper {
        .connect-error-content {
          color: rgba(255, 255, 255, 0.6);
        }

        .connect-error-switch {
          color: #176ce5;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          &:hover {
            cursor: pointer;
            color: #4787e3;
          }
        }
      }
    }
  }
}

.redeem-value-box {
  background: #050505;

  .Exchange-swap-section-top {
    padding-bottom: 16px;
  }

  .Exchange-swap-section-bottom {
    padding-bottom: 0;
  }

  .Modal-content {
    min-width: 400px;

    @media screen and (max-width: 700px) {
      min-width: unset;
    }
  }
}

.set-colateral {
  .Modal-content {
    min-width: 400px;

    @media screen and (max-width: 700px) {
      min-width: unset;
    }
  }
}

.TokenSelector {
  @media screen and (min-width: 1110px) {
    min-width: unset;

    .Modal-content {
      height: 100%;
    }
  }
}

.TokenSelector {
  @media screen and (max-width: 991px) {
    .Modal-content {
      padding-bottom: 24px;
    }

    .Modal-body {
      padding-bottom: 0 !important;
    }
  }
}

.Collateral-modal-deposit {
  .Tooltip-popup {
    min-width: 300px !important;
  }
}
.Modal-content {
  .divider-line-top {
    background: var(--Border, rgba(239, 236, 235, 0.08));
    height: 1px;
    width: 100%;
  }
}
.App-settings {
  .Modal-content {
    max-width: 424px;

    .Modal-body {
      padding: 16px 24px 24px 24px !important;
    }
  }
}

.settings-row-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;

  .setting-title {
    font-weight: 700;
    line-height: 140%;
  }

  .allowed-slippage {
    display: flex;
    align-items: center;
    gap: 8px;

    .slippage-tab {
      cursor: pointer;
      width: 56px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      height: 40px;
      background: none;

      @media screen and (max-width: 700px) {
        width: 45px;
      }

      &:hover {
        background: #ffffff1a;
      }

      &:disabled {
        cursor: no-drop;
      }
    }

    .slippage-tab--active {
      background: #176ce5 !important;
      color: #ffffff;

      &:hover {
        background: #4787e3 !important;
      }
    }
  }

  .App-slippage-tolerance-input-container {
    flex: 1;

    input {
      margin: 0 !important;
      height: 40px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;

      &::placeholder {
        font-weight: 500;
      }

      &:focus {
        border: 1px solid #176ce5;
      }
    }

    .App-slippage-tolerance-input-percent {
      top: 12px;
    }
  }

  .Checkbox-label {
    font-size: 14px !important;
  }

  .liquidity-source-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;

    background: #141312;

    .liquidity-source-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      max-height: 132px;
      overflow-y: auto;
      width: 100%;

      &::-webkit-scrollbar {
        width: 8px !important;
      }

      &::-webkit-scrollbar-track {
        width: 8px;
        background: #272727;
        border-radius: 100px;
      }

      &::-webkit-scrollbar-thumb {
        width: 6px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 100px;
      }

      .dex-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .token-img {
          width: 24px;
          height: 24px;
        }

        .name {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }
      }
    }

    .border-btn {
      width: 100%;
    }
  }
}

.theme--light {
  .liquidity-source-container {
    background: rgba(38, 77, 65, 0.07) !important;
  }

  .slippage-tab {
    border: 1px solid rgba(13, 26, 22, 0.07) !important;
  }

  .settings-row-container .allowed-slippage .slippage-tab--active {
    border: none !important;
    box-shadow: none !important;
    background: #02b27f !important;
    color: white !important;
  }

  .App-slippage-tolerance-input-container {
    input {
      &:focus {
        border: 1px solid #02b27f;
      }
    }
  }
}

.disabled {
  cursor: not-allowed !important;
}

.gmy-item.disabled {
  color: #919499 !important;
}
.App-content .Exchange-swap-box .Tooltip:not(.excep) .Tooltip-popup {
  width: 345px !important;
  @media screen and (max-width: 700px) {
    width: 304px !important;
    text-wrap: unset !important;
  }
}

.btn-fill {
  border-radius: var(--Border-Radius-Medium, 12px);
  background: var(--Button-Background, #176ce5);
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border: none;
  outline: none;
  color: var(--Button-Text, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  &:hover {
    background: var(--Button-Background, #0f5dcb);
  }
}

.btn-outline {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: var(--Border-Radius-Medium, 12px);
  border: 1px solid var(--Border, rgba(239, 236, 235, 0.08));
  color: var(--Button-Text-Link, #38f);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  background-color: transparent;
  &:hover {
    background: rgba(#fff, 0.05);
  }
  width: 304px !important;
}
