.ant-pagination-mini {
  display: flex;
  justify-content: flex-end;
  font-family: "Roboto";
  margin-top: 24px;
  color: #fff;
  gap: 8px;

  @media screen and (max-width: 400px) {
    gap: 4px;
  }

  > li {
    height: 40px !important;
  }

  * {
    color: #fff;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid #ffffff1a;
    border-radius: 8px;

    &:hover {
      border: 1px solid white;
    }
  }

  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px 16px;
    border: 1px solid #ffffff1a !important;
    border-radius: 8px;

    a {
      color: #fff !important;
      font-weight: 500;
    }
  }

  .ant-pagination-item-active {
    background-color: #176ce5;

    a {
      color: #fff !important;
    }
  }

  .ant-pagination-item-active:hover {
    border-color: transparent;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    width: 40px !important;
    height: 40px;
    display: flex;
    border: 1px solid #ffffff1a;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    line-height: unset;
  }

  .ant-pagination-item-ellipsis {
    color: #fff !important;
  }
}

.pagination-v2 {
  display: flex;
  justify-content: flex-end;
  font-family: "Roboto";
  margin-top: 16px;
  color: #fff;

  > li {
    height: 40px !important;
  }

  * {
    color: #fff;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: var(--Border-Radius-Medium, 12px);
    border: 2px solid var(--Border, rgba(239, 236, 235, 0.08));

    &:hover {
      border: 1px solid white;
    }
  }

  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px 16px;
    border-radius: var(--Border-Radius-Medium, 12px);
    border: 2px solid var(--Border, rgba(239, 236, 235, 0.08));

    a {
      color: #fff !important;
      font-weight: 500;
      font-family: "Roboto";
    }

    &:hover {
      border: 1px solid white;
    }
  }

  .ant-pagination-item-active {
    background: var(--Nature-3, #2e3033);

    a {
      color: #fff !important;
    }

    &:hover {
      // background: #176ce5 !important;
      // border: none !important;
      border-color: #fff !important;
    }
  }

  .ant-pagination-item-active:hover {
    border-color: transparent;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    width: 40px !important;
    height: 40px;
    display: flex;
    border: 1px solid #ffffff1a;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    line-height: unset;
    padding-bottom: 0;
  }

  .ant-pagination-item-ellipsis {
    color: #fff !important;
  }
  .ant-pagination-item-active {
    a {
      color: #fff !important;
    }
  }
  .ant-pagination-prev {
    padding-bottom: 0 !important;
  }
  .ant-pagination-next {
    padding-bottom: 0 !important;
  }
}
