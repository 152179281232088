.asset-menu-items {
  position: absolute;
  border: 1px solid #ffffff1a;
  border-radius: 0.4rem;
  background: #212224;
  outline: none;
  z-index: 100;
  transform: translate(-75%, 5px);
  white-space: nowrap;
  padding: 8px;
  border-radius: 16px;
}

.center-both {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-arrow {
  margin-left: 0.8rem;
  cursor: pointer;
  color: white;
  opacity: 0.8;
}
.dropdown-arrow:hover {
  opacity: 1;
}

.asset-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #a0a3c4;
  text-decoration: none;
  font-size: var(--font-sm);
  border-radius: var(--Base-Unit-S-12, 12px);
  padding: var(--Base-Unit-S-12, 12px);
  gap: var(--Base-Unit-XS-8, 8px);
}
.asset-item:hover {
  background: rgba(239, 236, 235, 0.08);
  color: white;
  border-radius: 12px;
}
.asset-item p {
  margin: 0;
  margin-left: 0.5rem;
}
.asset-item img {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}
