.position-share-modal .Modal-content {
  min-width: 36rem;
  width: 581px;
  font-family: "Roboto", sans-serif !important;
  .Modal-body {
    padding: 24px !important;
  }
}
.position-share-modal .actions {
  margin-top: 24px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr 1fr;
  img {
    width: 24px;
    margin-right: 4px;
  }
}
.checkbox-list {
  display: flex;
  margin-top: 24px;

  > div:not(:last-child) {
    margin-right: 24px;
  }
  > div {
    display: flex;
    align-items: center;
  }
  .checkbox-item {
    font-size: 14px;

    font-weight: 500;
    line-height: 140%;
  }
}
img.img-check {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  cursor: pointer;

  pointer-events: all;
}
.position-share-modal button,
.position-share-modal a {
  background: none;
  color: #fff;
  text-decoration: none;

  border: none;
}

.position-share-modal button {
  background: #176ce5;
  border-radius: 8px;
  padding: 8px 16px;
}
.position-share-modal button:disabled:hover {
  color: #fff !important;
}
.position-share-modal button:hover,
.position-share-modal a:hover {
  opacity: 1;
}

.position-share-modal button:active,
.position-share-modal a:active {
  opacity: 0.8;
}

.position-share-modal .icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.position-share {
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 24px 24px 24px;
  background-size: cover;
  background-position: right;
}
.position-share .logo_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}
.position-share .logo {
  width: 113px;
}
.position-share .chain_img {
  position: absolute;
  right: 0;
  top: 0;
}
.position-share .info {
  list-style: none;
  color: white;
  font-size: 14px;

  font-weight: 500;
  line-height: 140%;
  display: flex;
  padding-left: 0;
  margin-bottom: 10px;
}
.position-share .info li {
  padding: 0 12px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  &:last-child {
    border-right: none;
  }
  &:first-child {
    padding-left: 0;
  }

  padding-bottom: 0;
}

.position-share .info > .side.long {
  color: #7ee55c !important;
}
.position-share .info > .side.short {
  color: #f6475d !important;
}
.position-share {
  .positive {
    color: #7ee55c !important;
    span {
      color: #7ee55c !important;
    }
    div {
      color: #7ee55c !important;
    }
  }
  .negative {
    color: #f6475d !important;
    span {
      color: #f6475d !important;
    }
    div {
      color: #f6475d !important;
    }
  }
}
.position-share .pnl {
  font-weight: 500;
  font-size: 36px;
  line-height: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
  .change-dollar {
    margin-left: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
  }
}

.position-share .pnl.positive {
  color: #7ee55c !important;
}
.position-share .pnl.negative {
  color: #f6475d !important;
}
.tweet-link-container {
  height: 40px;
  a {
    height: 100%;
    text-decoration: none;
  }
}
.position-share .prices {
  > div {
    display: flex;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    &:first-child {
      margin-bottom: 4px;
    }
    p {
      margin: 0;
    }
  }
  margin-bottom: 24px;
}

.position-share .prices p.price {
  font-weight: 500;
  margin-left: 4px;
  color: #fed74c;
}

.position-share .referral-code {
  display: flex;

  align-items: center;
}
.position-share .referral-code div {
  display: flex;
  flex-direction: column;
}
.position-share .referral-code .label {
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #807e7c;
  margin-bottom: 8px;
}
.position-share .referral-code .referral-code-info {
  margin-left: 1rem;
}

.position-share .referral-code img {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}
.position-share .referral-code p {
  margin: 0;
}
.position-share .code {
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}

.tweet-link-container.disabled {
  cursor: not-allowed;
}

.position-share-modal .App-button-option {
  justify-content: center;
}

a.App-button-option.tweet-link {
  text-decoration: none;
  width: 100%;
  color: black !important;
  text-decoration: none !important;
}
a.App-button-option.tweet-link.disabled {
  pointer-events: none;
}

.image-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
}
.image-overlay-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #00000055;
  color: #fff;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  float: right;
  padding: 0 0.25rem;
  border-top-left-radius: 5px;
}
.image-overlay-wrapper .loading-text {
  font-size: 1.1rem;
  margin: 0 0 0 0.5rem;
}
.image-overlay {
  display: flex;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
}

.image-overlay .spinning-loader {
  margin-left: 0.5rem;
}
.position-share .blue {
  color: #0ecc83 !important;
}
.position-share .red {
  color: #f6475d !important;
}
@media (max-width: 700px) {
  .position-share .logo_wrapper {
    margin-bottom: 12px;
  }
  .position-share .referral-code .referral-code-info {
    margin-left: 8px;
  }
  .position-share .pnl .change-dollar {
    font-size: 14px;
  }
  .position-share-modal .Modal-content {
    max-width: calc(100vw - 32px);
    width: 343px;
    min-width: unset;
  }
  .position-share .info {
    margin-bottom: 8px;
  }
  .position-share-modal .Modal-content .Modal-body {
    padding: 8px !important;
  }
  .position-share-modal .Modal-title-bar {
    // padding: 12px 24px !important;
  }
  .position-share {
    padding: 12px;
  }
  .position-share .logo {
    width: 50px;
  }
  .position-share .info {
    font-size: 12px;
  }
  .position-share .pnl {
    font-size: 24px;
    margin-bottom: 8px;
  }
  .position-share .prices {
    margin-bottom: 12px;
  }
  .position-share .prices > div {
    font-size: 12px;
    &:first-child {
      margin-bottom: 0;
    }
  }
  .position-share .prices p {
    font-size: 12px;
  }
  .position-share .prices p.price {
    font-size: 12px;
  }
  .position-share .referral-code {
  }
  .position-share .referral-code .label {
    font-size: 12px;
  }
  .position-share .referral-code p {
    font-size: 12px;
  }
  .position-share-modal .actions {
    margin-top: 16px;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    button {
      margin-right: 0;
    }
  }
  .position-share-modal .actions .App-button-option {
  }
  .checkbox-list {
    margin-top: 16px;
  }
  .image-overlay-wrapper .loading-text {
    font-size: 1rem;
  }
}
