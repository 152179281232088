.percentage-btn-container {
  display: flex;
  gap: 4px;

  .percentahe-value {
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 4px;
    align-items: center;
    transition: all 150ms ease-in-out;
    border-radius: 8px;
    border: 1px solid var(--yellow, #efeceb14);
    padding-right: 8px;
    &:hover {
      opacity: 0.6;
    }
    &:has(input:focus) {
      opacity: 1;
    }
    input {
      width: 45px;
      max-height: 34px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      padding-left: 8px;
      padding-right: 0 !important;
      color: var(--yellow, #176ce5);

      &::placeholder {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        color: var(--yellow, #176ce5);
      }
    }

    span {
      color: var(--yellow, #176ce5);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
  .init-value {
    border: 1px solid #fff !important;
    input {
      color: #fff !important;
      &::placeholder {
        color: #fff !important;
      }
    }
    span {
      color: #fff !important;
    }
  }

  .percentage-btn {
    flex: 1;
    border-radius: 8px;
    border: 1px solid var(--white-10, rgba(255, 255, 255, 0.1));
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 36px;
    &:hover {
      background: var(--white-10, rgba(255, 255, 255, 0.1));
    }
  }

  .percentage-btn-selected {
    border: 1px solid #176ce5 !important;
    background: #c1ff991a;
  }
}
